import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { Helmet } from 'react-helmet';

import FeaturedCompanies from '../components/FeaturedCompanies';
import HITWCohorts from '../components/HITWCohorts';
import HITWFaqs from '../components/HITWFaqs';
import HITWFooter from '../components/HITWFooter';
import HITWHero from '../components/HITWHero';
import HITWOverview from '../components/HITWOverview';
import HITWQuotes from '../components/HITWQuotes';
import Layout from '../components/Layout';

const HumansInTheWild = ({ data }: any) => {
  const hitwData = data.prismicHumansInTheWildV2.data;
  const portfolioData = hitwData.featured_companies;

  return (
    <Layout variant="green" headerBG="beige">
      <Helmet>
        <title>Humans in the Wild</title>
        <meta property="og:title" content="Humans in the Wild" />
        <meta property="og:description" content="Apply to Humans in the Wild" />
      </Helmet>
      <HITWHero data={hitwData} />
      <HITWOverview data={hitwData} />
      <HITWQuotes data={hitwData} />
      <FeaturedCompanies data={portfolioData} showTitle />
      <HITWCohorts data={hitwData} />
      <HITWFaqs data={hitwData} />
      <HITWFooter data={hitwData} />
    </Layout>
  );
};

export default withPrismicPreview(HumansInTheWild);

export const query = graphql`
  query {
    prismicHumansInTheWildV2 {
      data {
        hero_description {
          html
        }
        applications_open
        notify_me {
          notify_description
          notify_button_text
          notify_button_url
        }
        apply_now {
          program_dates
          founders_notified
          applications_due
          apply_button_text
          apply_button_url
        }
        hitw_faq {
          faq_title {
            html
          }
          faq_description {
            html
          }
        }
        hitw_contact {
          html
        }
        title {
          html
        }
        body {
          html
        }
        overview_items {
          title
          body
          image {
            fluid {
              src
            }
            alt
          }
        }
        quotes {
          text {
            text
          }
          name
          image {
            fluid {
              src
            }
            alt
          }
          company
        }
        featured_companies {
          name
          description {
            html
          }
          company_color
          company_image {
            url
          }
          company_website {
            target
            url
          }
          founders {
            html
          }
        }
        body1 {
          ... on PrismicHumansInTheWildV2DataBody1CohortsOverview {
            id
            items {
              name
              photo {
                url
                alt
              }
            }
            primary {
              cohort_title
              cohort_subtitle
              cohort_description {
                html
              }
            }
          }
        }
        hitw_faq {
          faq_title {
            html
          }
          faq_description {
            html
          }
        }
        hitw_contact {
          html
        }
        apply_banner_subtitle
        apply_banner_title
        apply_banner_button_text
        apply_banner_button_url
        apply_banner_background_image {
          url
        }
      }
    }
  }
`;
