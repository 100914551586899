import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { getElementHeight } from '../scripts/utils';
import close from '../static/images/icons/close-small.svg';

const Container = styled(Box)`
  background-color: #f4f3ef;
  padding: 85px 30px 85px;

  @media (max-width: 800px) {
    padding: 32px 30px 16px;
  }

  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 100px;

    @media (max-width: 1400px) {
      padding: 0;
    }

    @media (max-width: 700px) {
      text-align: center;
      display: block;
    }
  }

  .past-cohorts__title {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: #a59d94;
    letter-spacing: 1.7px;
    text-align: left;
    margin-bottom: 40px;

    @media (max-width: 800px) {
      margin-bottom: 0;
      border-top: 1px solid rgba(165, 157, 148, 0.4);
      padding-top: 32px;
      text-align: center;
    }
  }
`;

const CohortsWrapper = styled(Box)<{ isOpen?: boolean }>`
  border-top: 1px solid rgba(165, 157, 148, 0.4);
  margin-bottom: 24px;
  padding-top: 28px;

  @media (max-width: 800px) {
    padding-top: 48px;

    &:first-of-type {
      border-top: none;
    }
  }

  .cohorts__header {
    position: relative;
    width: 100%;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;
    display: flex;
    align-items: flex-end;
    appearance: none;
    background: transparent;
    cursor: pointer;
    border: none;
		color: #000;

    @media (max-width: 800px) {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    &:hover {
      .cohorts__subtitle {
        color: transparent;
      }
    }
  }

  .cohorts__title {
    font-family: 'NoeDisplayRegularItalic', Georgia, serif;
    font-style: normal;
    font-size: clamp(40px, 5vw, 60px);
    line-height: 1;
    font-weight: normal;
    margin: 0;
    width: 30%;

    @media (max-width: 800px) {
      margin-bottom: 12px;
      width: 100%;
    }

    p {
      margin: 0;

      em {
        font-style: none !important;
      }
    }
  }

  .cohorts__summary {
    width: 50%;

    @media (max-width: 1000px) {
      width: 60%;
    }

    @media (max-width: 600px) {
      width: 80%;
    }
  }

  .cohorts__number,
  .cohorts__cta {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 11px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    line-height: 1;
  }

  .cohorts__number {
    margin-bottom: 10px;

    &--desktop {
      @media (max-width: 800px) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      margin-top: 0;
      margin-bottom: 20px;

      @media (max-width: 800px) {
        display: block;
      }
    }
  }

  .cohorts__subtitle {
    font-family: 'NoeDisplayRegularItalic', Georgia, serif;
    font-size: clamp(25px, 2.5vw, 30px);
    line-height: 1.3;
    letter-spacing: 0.5px;
    margin: 0;
    background-image: linear-gradient(36deg, #38c5ba 0%, #1baa9f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    transition: color 200ms ease-in-out;
  }

  .cohorts__cta {
    margin-left: auto;
    margin-bottom: 8px;
    line-height: 1.4;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    transition: all 200ms ease-in-out;

    @media (max-width: 800px) {
      transition-duration: 0ms;
      margin-left: 0;
    }

    &--desktop {
      display: grid;
      text-align: right;

      & > div {
        grid-area: 1/1;
      }

      @media (max-width: 800px) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      margin-top: 32px;
      opacity: ${p => (p.isOpen ? 0 : 1)};
      transition: all 200ms ease-in-out;
      transition-delay: ${p => (p.isOpen ? 0 : '420ms')};

      @media (max-width: 800px) {
        display: block;
      }
    }
  }

  .cohorts__logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 110px;
    height: auto;

    @media (max-width: 900px) {
      display: none;
    }
  }

  .cohorts__overview-wrapper {
    overflow: hidden;
    width: 100%;
    transition: height 500ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 800px) {
      position: relative;
      top: -28px;
    }
  }

  .cohorts__overview {
    display: flex;
    opacity: ${p => (p.isOpen ? 1 : 0)};
    padding: 32px 0 16px;
    transition: opacity 400ms cubic-bezier(0.65, 0.05, 0.36, 1) 50ms;

    @media (max-width: 1000px) {
      margin-left: 0;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      padding: 0;
    }
  }

  .cohorts__description {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 16px;
    width: 40%;
    margin-right: auto;
    text-align: left;

    @media (max-width: 1300px) {
      width: 40%;
    }

    @media (max-width: 800px) {
      width: 100%;
      font-size: 16px;
      padding-bottom: 40px;
      margin-top: 0;
    }

    p {
      margin: 0;

      & + p {
        margin-top: 16px;
      }

      a {
        font-size: 18px;
        line-height: 1.4;
        color: #1eaea3;
        transition: color 200ms cubic-bezier(0.65, 0.05, 0.36, 1);

        &:hover {
          color: #424143;
        }

        @media (max-width: 1300px) {
          font-size: 20px;
        }

        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
    }
  }

  .cohorts__class {
    flex: 0 1 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    width: 50%;
    padding: 0;

    @media (max-width: 1200px) {
      grid-gap: 40px 20px;
    }

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .cohort {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cohort__image {
    border-radius: 100px;
    max-width: 120px;
    margin-bottom: 4px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .cohort__name {
    font-size: 10px;
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.4;
    text-align: center;

    @media (max-width: 1000px) {
      font-size: 10px;
      letter-spacing: 1px;
    }

    @media (max-width: 800px) {
      font-size: 8px;
      letter-spacing: 0.5px;
    }
  }

  .cohorts__mobile-close {
    display: none;

    @media (max-width: 800px) {
      width: 180px;
      height: 52px;
      font-size: 15px;
      border-radius: 55px;
      border: 1px solid #424143;
      line-height: 1;
      display: flex;
      align-items: center;
      padding-bottom: 3px;
      justify-content: center;
      margin-top: 48px;
      position: relative;
      overflow: hidden;
      align-self: center;
      cursor: pointer;
      transition: color 350ms cubic-bezier(0.65, 0.05, 0.36, 1),
        background-color 350ms cubic-bezier(0.65, 0.05, 0.36, 1);

      &:hover {
        color: #f4f3ef;
        background-color: #143030;
      }
    }
  }
`;

const ArrowIcon: React.FC<any> = () => {
  return (
    <svg
      className="arrow-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      style={{
        transform: `scaleX(-1)`,
      }}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        d="M8,3.53553391 L1,3.53553391 M3.53553391,0 L-7.95585819e-13,3.53553391 L3.53553391,7.07106781"
        transform="translate(1 .964)"
      />
    </svg>
  );
};

const CohortsClass: React.FC<any> = ({ content, number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemHeight, setItemHeight] = useState<string | number | undefined>(0);
  const overview = useRef(null);

  const { cohort_title, cohort_subtitle, cohort_description } = content.primary;
  const cohorts = content.items;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setItemHeight(isOpen ? getElementHeight(overview.current) : 0);
  }, [isOpen]);

  return (
    <CohortsWrapper isOpen={isOpen} key={cohort_title}>
      <button
        className="cohorts__header"
        aria-expanded={isOpen}
        onClick={handleClick}
      >
        <p className="cohorts__number cohorts__number--mobile">
          Cohort No. {number}
        </p>
        {cohort_title && <h3 className="cohorts__title">{cohort_title}</h3>}
        {cohort_subtitle && (
          <div className="cohorts__summary">
            <p className="cohorts__number cohorts__number--desktop">
              Cohort No. {number}
            </p>
            <p className="cohorts__subtitle">{cohort_subtitle}</p>
          </div>
        )}

        <div className="cohorts__cta cohorts__cta--desktop">
          <AnimatePresence>
            {isOpen ? (
              <motion.div
                key="close"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2,
                  ease: [0.65, 0.05, 0.36, 1],
                }}
              >
                Close <img src={close} />
              </motion.div>
            ) : (
              <motion.div
                key="open"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.25,
                  ease: [0.65, 0.05, 0.36, 1],
                }}
              >
                Meet the Family <ArrowIcon />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="cohorts__cta cohorts__cta--mobile">
          Tap to learn more
        </div>
      </button>

      <div className="cohorts__overview-wrapper" style={{ height: itemHeight }}>
        <div className="cohorts__overview" ref={overview}>
          {cohort_description && (
            <div
              className="cohorts__description"
              dangerouslySetInnerHTML={{
                __html: cohort_description.html,
              }}
            />
          )}

          {cohorts && (
            <ul className="cohorts__class">
              {cohorts.map((cohort: any, i: number) => (
                <Cohort cohort={cohort} key={i} />
              ))}
            </ul>
          )}

          <button className="cohorts__mobile-close" onClick={handleClick}>
            Close
          </button>
        </div>
      </div>
    </CohortsWrapper>
  );
};

const Cohort: React.FC<any> = ({ cohort }) => {
  return (
    <li className="cohort">
      <div className="cohort__image">
        <Image src={cohort.photo.url} alt={cohort.name} />
      </div>

      <h3 className="cohort__name">{cohort.name}</h3>
    </li>
  );
};

const HITWCohorts: React.FC<any> = ({ data }) => {
  const { body1: cohortsByYear } = data;

  return (
    <Container>
      <div className="wrapper">
        <h2 className="past-cohorts__title">Past Cohorts</h2>

        {cohortsByYear.map((cohort: any, i: number) => (
          <CohortsClass
            content={cohort}
            number={cohortsByYear.length - i}
            key={i}
          />
        ))}
      </div>
    </Container>
  );
};

export default HITWCohorts;
