import React from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import HV_waves from '../static/images/hitw-header.jpg';
import arrowDown from '../static/images/icons/arrow-down-white.svg';
import HITW_wordmark from '../static/images/logos/hitw-wordmark.svg';
import HV_HITW_logo from '../static/images/logos/hv-hitw-logo.svg';

const HeroContainer = styled(Box)`
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
`;

const Hero = styled(Box)<any>`
  max-width: 1440px;
  padding: 160px 30px 52px 100px;

  @media (max-width: 1100px) {
    display: block;
    padding: 100px 30px 52px;
  }

  @media (max-width: 650px) {
    display: block;
    padding: 100px 30px 32px;
  }

  .hero__content {
    position: relative;
  }

  .hero__logo {
    position: absolute;
    right: 0;

    @media (max-width: 950px) {
      width: 220px;
      position: static;
      margin-bottom: 16px;
    }

    @media (max-width: 650px) {
      width: 168px;
    }
  }

  .hero__title {
    margin-top: 24px;
    margin-bottom: 48px;

    .visually-hidden {
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    @media (max-width: 650px) {
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }

  .hero__about {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    color: #ffffff;
    padding-right: 15px;
    max-width: 700px;
    letter-spacing: 0;
    font-size: 35px;
    line-height: 1.6;
    margin-bottom: 56px;

    @media (max-width: 950px) {
      margin-bottom: 48px;
    }

    @media (max-width: 800px) {
      padding-right: 5%;
    }

    @media (max-width: 650px) {
      font-size: 20px;
      margin-bottom: 34px;
    }

    a {
      color: #1eaea3;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
    }

    strong {
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      letter-spacing: -0.5px;
    }
  }

  .hero__apply,
  .hero__notify {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 950px) {
      background-color: #ffffff;
      border-radius: 8px;
      flex-direction: column;
      align-items: stretch;
      overflow: hidden;
    }

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      margin-bottom: 52px;
      background-image: linear-gradient(
        90deg,
        #1baeae 0%,
        #78f1dc 30%,
        #1baeae 60%,
        #78f1dc 100%
      );
      background-size: 300%;
      animation: gradient-slide 3s infinite linear forwards;

      @media (max-width: 950px) {
        display: none;
      }
    }
  }

  .apply__details {
    padding-left: 0;
    margin: 0;
    margin-right: 40px;
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    list-style-type: none;
    color: #ffffff;

    @media (max-width: 950px) {
      flex-direction: column;
      margin-right: 0;
    }

    li {
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      padding: 4px 1% 4px 0;
      margin-right: 5%;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 950px) {
        display: block;
      }

      @media (max-width: 1200px) {
        padding-right: 28px;
        margin-right: 24px;
      }

      @media (max-width: 950px) {
        padding: 16px;
        border-bottom: 1px solid #a59d94;
        margin-right: 0;
      }

      &:last-of-type {
        border: none;
        margin-right: 0;
        padding-right: 0;
      }
    }

    h3 {
      font-family: 'FFMarkWebProBold', 'Helvetica', Arial, sans-serif;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 8px;

      @media (max-width: 1050px) {
        font-size: 11px;
      }

      @media (max-width: 950px) {
        color: #a59d94;
        margin-bottom: 4px;
      }

      br {
        display: block;

        @media (max-width: 950px) {
          display: none;
        }
      }
    }

    p {
      font-size: 20px;
      margin: 0;

      @media (max-width: 1200px) {
        font-size: 18px;
      }

      @media (max-width: 950px) {
        color: #143030;
      }
    }

    @keyframes gradient-slide {
      0% {
        background-position: 100% 0;
      }
      100% {
        background-position: 0% 0;
      }
    }
  }

  .hero__notify {
    @media (max-width: 950px) {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      border: 1px solid #ffffff;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      padding: 32px;
    }
  }

  .notify__details {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: clamp(20px, 2vw, 26px);
    max-width: 55%;
    line-height: 1.4;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
    margin-right: auto;

    @media (max-width: 950px) {
      text-align: center;
      margin-right: 0;
      margin-bottom: 32px;
      max-width: 100%;
    }
  }

  .apply__button,
  .notify__button {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    color: #ffffff;
    font-size: 30px;
    line-height: 1;
    display: flex;
    padding: 20px 24px 22px 32px;
    margin-bottom: 2px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-radius: 50px;
    box-shadow: inset 0 0 0 1px #38c5ba;

    @media (max-width: 1200px) {
      padding: 16px 20px 18px 24px;
      font-size: 26px;
    }

    @media (max-width: 1050px) {
      padding: 12px 20px 16px 24px;
      font-size: 22px;
      align-items: center;
      margin-bottom: 0;
    }

    @media (max-width: 950px) {
      border-radius: 0;
      padding-top: 14px;
      justify-content: space-between;
      box-shadow: none;
    }

    &:hover {
      &:before {
        opacity: 0;

        @media (max-width: 950px) {
          opacity: 0.9;
        }
      }

      img {
        transform: rotate(-90deg) translateY(4px);
      }
    }

    &:before {
      content: '';
      background-image: linear-gradient(36deg, #38c5ba 0%, #1baa9f 100%);
      background-size: 100%;
      background-position: left center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 1;
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    img {
      width: 36px;
      height: 36px;
      transform: rotate(-90deg);
      margin-left: 16px;
      transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 1200px) {
        width: 28px;
        height: 28px;
      }
    }
  }

  .apply__button--smaller {
    @media (max-width: 1300px) {
      padding: 16px 20px 18px 24px;
      font-size: 26px;
      align-items: center;
    }

    @media (max-width: 1200px) {
      padding: 12px 20px 14px 24px;
      font-size: 20px;
      margin-bottom: 0;
    }

    @media (max-width: 1000px) {
      padding: 12px 18px 14px 22px;
      font-size: 18px;
      margin-bottom: 0;
    }

    @media (max-width: 950px) {
      font-size: 22px;
    }

    img {
      @media (max-width: 1300px) {
        width: 30px;
        height: 28px;
      }

      @media (max-width: 1200px) {
        width: 24px;
        height: 28px;
      }

      @media (max-width: 1000px) {
        width: 20px;
        height: 28px;
      }
    }
  }

  .notify__button {
    box-shadow: inset 0 0 0 1px #ffffff;
    transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 950px) {
      border-radius: 50px;
      padding-top: 14px;
      justify-content: unset;
      box-shadow: inset 0 0 0 1px #ffffff;
    }

    &:before {
      opacity: 0;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    img {
      @media (max-width: 1200px) {
        width: 28px;
        height: 28px;
        margin-left: 32px;
      }
    }
  }
`;

const HITWHero: React.FC<any> = ({ data }) => {
  const { hero_description, applications_open, apply_now, notify_me } = data;

  return (
    <HeroContainer
      sx={{
        backgroundImage: ` url(${HV_waves})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Hero mx="auto">
        <Box className="hero__content">
          <Image
            src={HV_HITW_logo}
            className="hero__logo"
            alt="Human Ventures & Humans in the Wild Logo"
          />

          <h1 className="hero__title">
            <Image src={HITW_wordmark} alt="Humans in the Wild" />
            <div className="visually-hidden">
              Humans in the Wild<span>&trade;</span>
            </div>
          </h1>
          <div
            className="hero__about"
            dangerouslySetInnerHTML={{ __html: hero_description.html }}
          />
        </Box>

        {applications_open && (
          <div className="hero__apply">
            <ul className="apply__details">
              <li>
                {/* <h3>Applications Due</h3> */}
                <h3>Applications Due</h3>
                <p>{apply_now[0].applications_due}</p>
              </li>
              <li>
                <h3>Founders Notified</h3>
                <p>{apply_now[0].founders_notified}</p>
              </li>
              <li>
                <h3>Program Dates</h3>
                <p>{apply_now[0].program_dates}</p>
              </li>
            </ul>

            <a
              href={apply_now[0].apply_button_url}
              className="apply__button apply__button--smaller"
            >
              {apply_now[0].apply_button_text}
              <Image src={arrowDown} alt="Arrow" />
            </a>
          </div>
        )}

        {!applications_open && (
          <div className="hero__notify">
            <p className="notify__details">{notify_me[0].notify_description}</p>

            <a href={notify_me[0].notify_button_url} className="notify__button">
              {notify_me[0].notify_button_text}
              <Image src={arrowDown} alt="Arrow" />
            </a>
          </div>
        )}
      </Hero>
    </HeroContainer>
  );
};

export default HITWHero;
