import React from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import useFlickity from '../hooks/useFlickity';
import { isBrowser } from '../scripts/utils';
import arrowDown from '../static/images/icons/arrow-down-dark.svg';
import { ArrowIcon } from './ArrowIcon';

const Container = styled(Box)`
  background-color: #f4f3ef;
  padding: 32px 0;

  @media (max-width: 600px) {
    padding: 0 30px;
  }

  .wrapper {
    width: 100%;
    position: relative;
  }

  .featured__title {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: #a59d94;
    letter-spacing: 1.7px;
    text-align: center;
    margin-bottom: 56px;
  }

  /* Featured Carousel */
  .featured-carousel {
    position: relative;

    &:hover .carousel__navigation {
      opacity: 1;
    }
  }

  .featured-companies {
    position: relative;
    padding: 0;
    margin: 16px 0;
    height: 380px;

    @media (max-width: 1000px) {
      height: auto;
    }

    &:focus {
      outline: none;
    }

    .featured__cell {
      margin: 0 36px;
			display: none;

      @media (max-width: 600px) {
        margin: 0 16px;
        width: calc(100% - 16px);
      }

      .featured__cell__inner {
        transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1),
          box-shadow 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
      }

      &.is-selected {
        .featured__cell__inner {
          transform: translateY(-10px);

          @media (max-width: 600px) {
            box-shadow: 0 1.3px 0.8px rgba(0, 0, 0, 0.008),
              0 3.2px 2px rgba(0, 0, 0, 0.012), 0 6px 3.8px rgba(0, 0, 0, 0.015),
              0 10.7px 6.7px rgba(0, 0, 0, 0.018),
              0 20.1px 12.5px rgba(0, 0, 0, 0.022),
              0 48px 30px rgba(0, 0, 0, 0.03);
          }
        }
      }
    }

		&.flickity-enabled {
			.featured__cell {
				display: block;
			}
		}
  }

  .featured {
    width: 60vw;
    max-width: 1000px;
    min-width: 760px;
    height: 380px;
    display: flex;
    color: #143030;
    overflow: hidden;
    transition: box-shadow 400ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 1000px) {
      flex-direction: column;
      height: auto;
      min-width: 100%;
    }

    &:hover {
      box-shadow: 0 1.3px 0.8px rgba(0, 0, 0, 0.008),
        0 3.2px 2px rgba(0, 0, 0, 0.012), 0 6px 3.8px rgba(0, 0, 0, 0.015),
        0 10.7px 6.7px rgba(0, 0, 0, 0.018),
        0 20.1px 12.5px rgba(0, 0, 0, 0.022), 0 48px 30px rgba(0, 0, 0, 0.03);

      .featured__overview .arrow-icon {
        transform: translate(2px, -2px);

        @media (max-width: 1000px) {
          transform: none;
        }

        path {
          stroke: var(--color);
        }
      }

      .featured__image img {
        transform: scale(1.04) translate3d(0px, 0px, 1px);
      }

      .featured__name {
        color: var(--color);
      }
    }
  }

  .featured__image {
    flex: 1 1 60%;
    height: 100%;
    background-color: #d7d7d7;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 450ms cubic-bezier(0.65, 0.35, 0.36, 1);
    }

    @media (max-width: 1000px) {
      height: 300px;
    }
  }

  .featured__overview {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 380px;
    height: 380px;
    padding: 40px 36px 30px;
    background-color: #ffffff;
    position: relative;

    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
      padding: 24px 72px 24px 20px;
    }
  }

  .featured__name {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    margin: 0;
    font-size: 35px;
    line-height: 0.9;
    font-weight: normal;
    margin-bottom: 10px;
    transition: color 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 1000px) {
      font-size: 28px;
      margin-bottom: 16px;
    }
  }

  .featured__description {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    line-height: 1.4;
    margin: 0;

    @media (max-width: 1000px) {
      font-size: 16px;
    }
  }

  .featured__footer {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
  }

  .featured__founders {
    margin: 0;
    margin-right: auto;

    @media (max-width: 1000px) {
      display: none;
    }

    h4 {
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      font-size: 8px;
      line-height: 1;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    ul {
      margin: 0;
      font-size: 15px;
      line-height: 1.3;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin-left: 0;
    }

    li + li {
      margin-top: 4px;
    }
  }

  .featured__overview .arrow-icon {
    position: absolute;
    right: 36px;
    transform-origin: left bottom;

    @media (max-width: 1000px) {
      top: 24px;
      right: 16px;
      width: 20px;
      height: 20px;
      transform-origin: top right;
    }

    path {
      @media (max-width: 1000px) {
        stroke-width: 2;
      }
    }

    &,
    & path {
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }

  .carousel__navigation {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: 60vw;
    max-width: 1000px;
    min-width: 760px;
    opacity: 0;
    transition: all 400ms cubic-bezier(0.65, 0.05, 0.36, 1) 50ms;

    @media (max-width: 1000px) {
      flex-direction: column;
      height: auto;
      min-width: initial;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .slideshow-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: 32px;
    background-color: #ffffff;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms cubic-bezier(0.65, 0.05, 0.36, 1);

    &:hover {
      transform: translateY(-50%) scale(1.2);

      &.previous:before {
        transform: rotate(90deg) translate(0, 110%);
      }

      &.previous:after {
        transform: rotate(90deg) translate(0, 8px);
        transition-delay: 75ms;
      }

      &.next:before {
        transform: rotate(-90deg) translate(0, 8px);
        transition-delay: 75ms;
      }

      &.next:after {
        transform: rotate(-90deg) translate(0, 110%);
      }
    }

    &:focus {
      outline: none;
    }

    &:after,
    &:before {
      content: url(${arrowDown});
      width: 16px;
      height: 16px;
      display: block;
      transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &.previous {
      left: -52px;

      &:before {
        transform: rotate(90deg) translate(0, -8px);
      }

      &:after {
        transform: rotate(90deg) translate(0, -110%);
        transition-delay: 0;
      }
    }

    &.next {
      right: -52px;

      &:before {
        transform: rotate(-90deg) translate(0, -110%);
        transition-delay: 0;
      }

      &:after {
        transform: rotate(-90deg) translate(0, -8px);
      }
    }
  }

  .flickity-buttons,
  .flickity-button-icon {
    display: none;
  }

  .flickity-viewport {
    overflow: visible;
  }
`;

const Company: React.FC<any> = props => {
  const {
    name: companyName,
    description,
    company_image: image,
    company_color: brandColor,
    company_website: url,
    founders,
  } = props.company;

  return (
    <div className="featured__cell">
      <div className="featured__cell__inner">
        <a href={url.url} target="blank" className="featured">
          <div className="featured__image">
            <Image src={image.url} alt={companyName} />
          </div>

          <div className="featured__overview">
            <h3 className="featured__name">{companyName}</h3>
            <div
              className="featured__description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />

            <div className="featured__footer">
              <div className="featured__founders">
                <h4>Founded By:</h4>
                <div dangerouslySetInnerHTML={{ __html: founders.html }} />
              </div>

              <ArrowIcon color={brandColor} />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

const flickityOptions = {
  wrapAround: true,
  autoPlay: 5000,
  pageDots: false,
  prevNextButtons: false,
  pauseAutoPlayOnHover: true,
  adaptiveHeight: false,
  cellAlign:
    isBrowser && window.matchMedia('screen and (max-width: 600px)').matches
      ? 'left'
      : 'center',
  selectedAttraction: 0.03,
  friction: 0.27,
};

const FeaturedCompanies = ({ data: companies, showTitle = false }: any) => {
  const { ref, flickity, flickityRef } = useFlickity(flickityOptions);

  const handleClick = (orientation: any) => {
    if (orientation === 'next') {
      flickity.current?.next();
    } else if (orientation === 'prev') {
      flickity.current?.previous();
    }
  };

  const handlePause = () => {
    flickity.current?.pausePlayer();
  };

  const handleUnpause = () => {
    flickity.current?.unpausePlayer();
  };

  return (
    <>
      <Container>
        <div className="wrapper">
          {showTitle && <h2 className="featured__title">Featured Companies</h2>}

          <div ref={ref}>
            <div className="featured-carousel">
              <div className="carousel__navigation">
                <button
                  className="slideshow-arrows previous"
                  type="button"
                  aria-label="Previous"
                  onClick={() => {
                    handleClick('prev');
                  }}
                  onMouseOver={handlePause}
                  onMouseOut={handleUnpause}
                />
                <button
                  className="slideshow-arrows next"
                  type="button"
                  aria-label="Next"
                  onClick={() => {
                    handleClick('next');
                  }}
                  onMouseOver={handlePause}
                  onMouseOut={handleUnpause}
                />
              </div>

              <div className="featured-companies" ref={flickityRef}>
                {companies.map((company: any, i: number) => (
                  <Company company={company} index={i} key={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default FeaturedCompanies;
