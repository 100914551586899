import { motion } from 'framer-motion';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { isBrowser } from '../scripts/utils';

const Container = styled(Box)`
  background-color: #f4f3ef;
  padding: 0 30px;

  @media (max-width: 700px) {
    padding: 0 30px;
  }

  .wrapper {
    max-width: 1250px;
    margin: 0 auto;
    padding: 72px 0;

    @media (max-width: 1400px) {
      padding: 36px 0;
    }

    @media (max-width: 700px) {
      text-align: center;
      padding: 28px 0 48px;
      border-bottom: 1px solid #dddad4;
    }
  }
`;

const OverviewIntro = styled.div`
  max-width: 700px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 700px) {
    margin: 0 auto 48px;
  }

  h2 {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: #a59d94;
    letter-spacing: 1.7px;
    margin-bottom: 32px;

    @media (max-width: 700px) {
      margin-bottom: 24px;
    }
  }

  h3 {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-style: normal;
    font-size: clamp(34px, 5vw, 45px);
    line-height: 1.3;
    font-weight: normal;
    margin: 0 0 24px;

    @media (max-width: 700px) {
      margin: 0 0 20px;
    }

    em {
      font-family: 'NoeDisplayRegularItalic', Georgia, serif;
      display: block;
      color: #b37f57;
      font-style: normal;
    }
  }

  p {
    margin: 0;
  }

  .intro__body {
    font-size: 18px;
    line-height: 1.5;
  }
`;

const OverviewItems = styled.ul`
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    & + li {
      margin-top: 24px;
      padding-top: 48px;
      border-top: 1px solid #dddad4;

      @media (max-width: 700px) {
        border-top: none;
        padding-top: 0;
        margin-top: 40px;
      }
    }
  }

  .overview__image {
    position: relative;
    width: 50%;

    @media (max-width: 700px) {
      width: 100%;
    }

    &:before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: calc(100% / 3 * 2);
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .overview__text {
    margin-left: clamp(48px, 10vw, 144px);

    @media (max-width: 700px) {
      margin-left: 0;
    }
  }

  .overview__number {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-weight: normal;
    font-size: clamp(20px, 4vw, 50px);
    margin: 0;
    position: relative;

    @media (max-width: 700px) {
      display: none;
    }

    &:before {
      content: 'fig.';
      font-family: 'NoeDisplayRegularItalic', Georgia, serif;
      font-size: 15px;
      text-align: right;
      position: absolute;
      left: -40px;
      top: 33%;

      @media (max-width: 1000px) {
        left: -28px;
        top: 25%;
      }
    }
  }

  .overview__title {
    font-family: 'NoeDisplayRegularItalic', Georgia, serif;
    font-weight: normal;
    font-size: clamp(35px, 5vw, 60px);
    line-height: 1;
    margin: 20px 0 24px;
    max-width: 10ch;

    @media (max-width: 900px) {
      margin: 10px 0 12px;
    }

    @media (max-width: 700px) {
      max-width: 100%;
      margin: 24px 0 8px;
    }
  }

  .overview__body {
    font-size: 18px;
    line-height: 1.6;
    max-width: 25ch;
    margin: 0;

    @media (max-width: 700px) {
      max-width: 25ch;
    }
  }
`;

const HITWOverview: React.FC<any> = ({ data }) => {
  const { title, body, overview_items } = data;

  const isMobile =
    isBrowser && window.matchMedia('screen and (max-width: 700px)').matches;

  const container = {
    hidden: { y: isMobile ? 10 : 0, x: isMobile ? 0 : -12, opacity: 0 },
    show: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  };

  return (
    <Container>
      <div className="wrapper">
        <OverviewIntro>
          <h2>Program Overview</h2>
          <h3 dangerouslySetInnerHTML={{ __html: title.html }} />
          <div
            className="intro__body"
            dangerouslySetInnerHTML={{ __html: body.html }}
          />
        </OverviewIntro>

        <OverviewItems>
          {overview_items.map((item: any, i: number) => (
            <InView triggerOnce rootMargin="-75px 0px" key={item.title}>
              {({ inView, ref }) => (
                <li>
                  <span ref={ref} />
                  <motion.div
                    className="overview__image"
                    initial={{ y: 15, opacity: 0 }}
                    animate={{ y: inView ? 0 : 15, opacity: inView ? 1 : 0 }}
                    transition={{
                      ease: [0.68, 0.55, 0.27, 1],
                      duration: 0.5,
                    }}
                  >
                    <Image src={item.image.fluid.src} alt={item.image.alt} />
                  </motion.div>

                  <div className="overview__text">
                    <motion.div
                      className="overview__number"
                      variants={container}
                      initial="hidden"
                      animate={inView ? 'show' : 'hidden'}
                      transition={{
                        ease: [0.68, 0.55, 0.27, 1],
                        duration: 0.4,
                        delay: 0.15,
                      }}
                    >
                      0{i + 1}
                    </motion.div>
                    <motion.h4
                      className="overview__title"
                      variants={container}
                      initial="hidden"
                      animate={inView ? 'show' : 'hidden'}
                      transition={{
                        ease: [0.68, 0.55, 0.27, 1],
                        duration: 0.4,
                        delay: 0.2,
                      }}
                    >
                      {item.title}
                    </motion.h4>
                    <motion.p
                      className="overview__body"
                      variants={container}
                      initial="hidden"
                      animate={inView ? 'show' : 'hidden'}
                      transition={{
                        ease: [0.68, 0.55, 0.27, 1],
                        duration: 0.4,
                        delay: 0.25,
                      }}
                    >
                      {item.body}
                    </motion.p>
                  </div>
                </li>
              )}
            </InView>
          ))}
        </OverviewItems>
      </div>
    </Container>
  );
};

export default HITWOverview;
