import React from 'react';

import { CSSVariableColor } from './CSSVariableColor';

export const ArrowIcon: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <CSSVariableColor
      as="svg"
      className="arrow-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      varColor={color}
    >
      <path
        fill="none"
        stroke="currentColor"
        d="M0.833333333,0.833333333 L41.1645609,0.831227594 L41.1666667,41.1666667 M0.833333333,41.1666667 L41.1666667,0.833333333"
      />
    </CSSVariableColor>
  );
};
