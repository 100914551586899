import React, { useState, useRef, useEffect } from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { getElementHeight } from '../scripts/utils';

import Accordion from './Accordion';

import angelLogo from '../static/images/logos/hfa-hv-logo.svg';

const Container = styled(Box)`
  background-color: ${p => (p.variant === 'angels' ? '#f4f3ef' : '#ffffff')};
  padding: 0 30px 48px;
  position: relative;
  ${p =>
    p.variant === 'angels' &&
    `
    max-width: 1680px;
    margin: 0 auto;
  `};

  @media (max-width: 700px) {
    padding: 0 30px 24px;
  }

  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    ${p =>
      p.variant === 'angels'
        ? `
        padding: 0 24px 48px;

      `
        : `
      padding: 0 100px 48px;
`};

    @media (max-width: 1400px) {
      padding: 0;
    }

    @media (max-width: 700px) {
      text-align: center;
      display: block;
    }

    .faq-list {
      margin: 0;
      padding: 0;

      ${p =>
        p.variant === 'angels' &&
        `
        max-width: 940px;
        width: 70%;
      `};

      @media (max-width: 1000px) {
        ${p =>
          p.variant === 'angels' &&
          `
        width: 100%;
      `};
      }
    }
  }

  .section-title {
    font-family: ${p =>
      p.variant === 'angels'
        ? `'NoeDisplayRegular', Georgia, serif`
        : `'FFMarkWebProLight', 'Helvetica', Arial, sans-serif`};
    font-weight: normal;
    font-size: ${p => (p.variant === 'angels' ? '45px' : '60px')};
    line-height: 1;
    letter-spacing: 0.5px;
    padding-top: 80px;
    margin-top: 0;
    margin-bottom: 24px;
    text-align: ${p => (p.variant === 'angels' ? 'center' : 'left')};
    color: ${p => (p.variant === 'angels' ? '#143030;' : '')};

    @media (max-width: 1000px) {
      font-size: 40px;
      letter-spacing: 0;
    }

    @media (max-width: 700px) {
      font-size: ${p => (p.variant === 'angels' ? '36px' : '22px')};
      line-height: 1.2;
      margin-bottom: 8px;
      padding-top: 48px;
    }
  }

  .faq {
    border-bottom: 1px solid rgba(165, 157, 148, 0.4);
    padding: 0;
    list-style-type: none;
    text-align: left;

    &.is-open {
      border-color: #1eafa3;

      .faq__header {
        -webkit-tap-highlight-color: transparent;

        h3 {
          color: ${p => (p.variant === 'angels' ? '#424143' : '#1eaea3')};
          ${p =>
            p.variant === 'angels' &&
            `
            font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;

          `}
        }

        .plus-icon {
          transform: rotate(135deg);
          stroke: ${p => (p.variant === 'angels' ? '#424143' : '#1eaea3')};
        }
      }

      .faq__body {
        opacity: 1;
      }
    }
  }

  .faq__header {
    padding: 40px 0;
    width: 100%;
    margin-right: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    appearance: none;
    border: none;
    background-color: transparent;
    text-align: left;
		color: #000;

    @media (hover: hover) {
      &:hover {
        h3 {
          color: ${p => (p.variant === 'angels' ? '#424143' : '#1eaea3')};
        }

        .plus-icon {
          stroke: #1eaea3;
        }
      }
    }

    @media (max-width: 1000px) {
      justify-content: space-between;
    }

    @media (max-width: 700px) {
      padding: 28px 0;
    }

    em {
      font-family: 'FFMarkWebProLightItalic', 'Helvetica', Arial, sans-serif;
    }

    h3 {
      font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
      font-weight: normal;
      font-size: 25px;
      line-height: 1.5;
      margin-top: 0;
      margin-left: 60px;
      margin-bottom: 2px;
      padding-right: 24px;
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 1000px) {
        margin-left: 0;
      }

      @media (max-width: 700px) {
        font-size: 16px;
      }

      p {
        margin: 0;
      }
    }

    .plus-icon {
      flex: 0 0 auto;
      width: 32px;
      height: 32px;
      position: absolute;
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 1000px) {
        position: static;
      }
    }
  }

  .faq__body-wrapper {
    overflow: hidden;
    width: 100%;
    transition: height 500ms cubic-bezier(0.65, 0.05, 0.36, 1);
    margin-left: 60px;

    @media (max-width: 1000px) {
      margin-left: 0;
    }
  }

  .faq__body {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding-right: 24px;
    padding-bottom: 32px;
    max-width: 800px;
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.65, 0.05, 0.36, 1) 50ms;

    @media (max-width: 700px) {
      margin-top: 0;
    }

    em {
      font-family: 'FFMarkWebProLightItalic', 'Helvetica', Arial, sans-serif;
    }

    p,
    a {
      font-size: 20px;
      line-height: 1.5;

      @media (max-width: 1000px) {
        font-size: 22px;
      }

      @media (max-width: 700px) {
        font-size: 16px;
      }
    }

    a {
      color: #1eaea3;
      transition: color 200ms cubic-bezier(0.65, 0.05, 0.36, 1);

      &:hover {
        color: #424143;
      }
    }

    p {
      margin: 0;
    }

    p + p {
      margin-top: 32px;

      @media (max-width: 700px) {
        margin-top: 16px;
      }
    }

    p + ul {
      margin-top: 8px;
    }

    ul {
      margin-left: 0;
      margin-bottom: 32px;
      line-height: 1.5;

      @media (max-width: 700px) {
        margin-bottom: 16px;
      }
    }

    li + li {
      margin-top: 12px;
    }

    strong {
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      font-weight: normal;
    }
  }

  .hitw-contact {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 32px;
    text-align: left;

    @media (max-width: 700px) {
      font-size: 13px;
      margin-top: 24px;
    }

    a {
      font-size: 18px;
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      transition: color 200ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 700px) {
        font-size: 13px;
      }

      &:hover {
        color: #1eaea3;
      }
    }
  }
`;

const AngelLogo = styled(Image)`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  margin-top: 48px;
  margin-bottom: 10px;

  @media (min-width: 1000px) {
    position: absolute;
    bottom: 48px;
    right: 32px;
    transform: none;
    left: initial;
    width: 232px;
    margin: 0;
  }

  @media (min-width: 1400px) {
    bottom: 100px;
    right: 48px;
  }
`;

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    stroke="#143030"
    className="plus-icon"
  >
    <title>PlusIcon</title>
    <g fill="none" fillRule="evenodd" transform="translate(0 -.063)">
      <path d="M25.6103374,5.86700264 C20.566233,0.00693170948 11.726981,-0.654443095 5.86691484,4.38966543 C0.00709885132,9.43352381 -0.654525552,18.2730332 4.38957883,24.132854 C9.43368322,29.9929249 18.2729352,30.6545499 24.1330013,25.6104413 C29.9930675,20.566583 30.6544417,11.7270736 25.6103374,5.86700264 Z" />
      <g strokeLinecap="square" transform="rotate(45 -.364 21.364)">
        <line
          x1="-1.823"
          x2="14.177"
          y1="6.177"
          y2="6.177"
          transform="rotate(-45 6.177 6.177)"
        />
        <line
          x1="-1.823"
          x2="14.177"
          y1="6.177"
          y2="6.177"
          transform="rotate(45 6.177 6.177)"
        />
      </g>
    </g>
  </svg>
);

const FAQ = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemHeight, setItemHeight] = useState<string | number | undefined>(0);
  const itemBody = useRef(null);

  const { faq_title: title, faq_description: description } = props.faq;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setItemHeight(isOpen ? getElementHeight(itemBody.current) : 0);
  }, [isOpen]);

  return (
    <li className={`faq ${isOpen ? 'is-open' : ''}`}>
      <button
        aria-expanded={isOpen}
        onClick={handleClick}
        className="faq__header"
      >
        <h3 dangerouslySetInnerHTML={{ __html: title.html }} />
        <PlusIcon />
      </button>

      <div className="faq__body-wrapper" style={{ height: itemHeight }}>
        <div
          ref={itemBody}
          className="faq__body"
          dangerouslySetInnerHTML={{ __html: description.html }}
        />
      </div>
    </li>
  );
};

const HITWFaqs: React.FC<any> = ({ data, variant }) => {
  const { hitw_faq, hitw_contact, angels_faq, angels_contact } = data;
  const faqs = variant === 'angels' ? angels_faq : hitw_faq;

  return (
    <Container variant={variant}>
      <div className="wrapper">
        <h2 className="section-title">Frequently Asked Questions</h2>

        <Accordion type="faqs" classes="faq-list" isAccordion>
          {faqs.map((faq: any, i: number) => (
            <FAQ faq={faq} isAccordion key={i} />
          ))}
        </Accordion>

        <div
          className="hitw-contact"
          dangerouslySetInnerHTML={{
            __html:
              variant === 'angels' ? angels_contact.html : hitw_contact.html,
          }}
        />
      </div>

      {variant === 'angels' && <AngelLogo src={angelLogo} />}
    </Container>
  );
};

export default HITWFaqs;
