import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import useFlickity from '../hooks/useFlickity';
import arrowDown from '../static/images/icons/arrow-down-dark.svg';

const Container = styled(Box)`
  background-color: #f4f3ef;
  padding: 32px;
  padding-top: clamp(40px, 5vw, 80px);
  padding-bottom: clamp(40px, 5vw, 80px);

  @media (max-width: 850px) {
    padding-left: 0;
    padding-right: 0;
  }

  .wrapper {
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;
    position: relative;
    display: grid;

    &--desktop {
      max-height: 95vh;

      @media (max-width: 850px) {
        display: none;
      }

      &:before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 50%;
      }

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: 850px) {
        display: block;
      }

      .slick-slide {
        margin: 0 8px;
        height: inherit;

        & > div:first-of-type {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    }
  }

  .mobile-slider {
    .flickity-slider {
      min-height: 100%;
    }

    .quote {
      width: 85%;
      margin: 0 12px;
      min-height: 100%;
      display: flex;
    }

    .quote__inner {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    img {
      display: flex;
    }
  }

  .carousel__title {
    font-family: 'FFMarkWebProLightItalic', 'Helvetica', Arial, sans-serif;
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: #a59d94;
    letter-spacing: 1.7px;
    text-align: center;
    margin: 0;
    grid-row: 1 / 2;

    @media (max-width: 850px) {
      margin-bottom: 40px;
    }
  }

  .quotes__text {
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    padding: clamp(16px, 4.5vw, 88px);
    position: absolute;
    top: 0;
    overflow: hidden;
    display: grid;
    grid-template-rows: 24px 1fr 40px;
    align-items: center;

    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
    }
  }

  .quotes__text-carousel {
    overflow: hidden;
    height: 100%;
  }

  .quote__credit {
    text-align: center;

    @media (max-width: 850px) {
      margin-top: auto;
    }

    p {
      margin: 0;

      &:first-of-type {
        font-family: 'NoeDisplayRegularItalic', Georgia, serif;
        font-size: clamp(25px, 2vw, 35px);
      }

      &:last-of-type {
        text-transform: uppercase;
        font-size: clamp(10px, 1.5vw, 15px);
        letter-spacing: 1px;
      }
    }
  }

  .quotes__image {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 850px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .featured__overview .arrow-icon {
    position: absolute;
    right: 36px;
    transform-origin: left bottom;

    @media (max-width: 1000px) {
      top: 24px;
      right: 16px;
      width: 20px;
      height: 20px;
      transform-origin: top right;
    }

    path {
      @media (max-width: 1000px) {
        stroke-width: 2;
      }
    }

    &,
    & path {
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }

  .carousel__navigation {
    position: relative;
    z-index: 1;
    grid-row: 3 / 4;
    overflow: hidden;
    transition: all 400ms cubic-bezier(0.65, 0.05, 0.36, 1) 50ms;

    @media (max-width: 1000px) {
      flex-direction: column;
      height: auto;
      min-width: initial;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .slideshow-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: 32px;
    background-color: #ffffff;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms cubic-bezier(0.65, 0.05, 0.36, 1);
    z-index: 10;

    &:hover {
      transform: translateY(-50%) scale(1.2);

      &.previous:before {
        transform: rotate(90deg) translate(0, 110%);
      }

      &.previous:after {
        transform: rotate(90deg) translate(0, 8px);
        transition-delay: 75ms;
      }

      &.next:before {
        transform: rotate(-90deg) translate(0, 8px);
        transition-delay: 75ms;
      }

      &.next:after {
        transform: rotate(-90deg) translate(0, 110%);
      }
    }

    &:focus {
      outline: none;
    }

    &:after,
    &:before {
      content: url(${arrowDown});
      width: 16px;
      height: 16px;
      display: block;
      transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &.previous {
      left: 0;

      &:before {
        transform: rotate(90deg) translate(0, -8px);
      }

      &:after {
        transform: rotate(90deg) translate(0, -110%);
        transition-delay: 0;
      }
    }

    &.next {
      right: 0;

      &:before {
        transform: rotate(-90deg) translate(0, -110%);
        transition-delay: 0;
      }

      &:after {
        transform: rotate(-90deg) translate(0, -8px);
      }
    }
  }

  .text-slider {
    grid-row: 2 / 3;
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: center;

    @media (max-width: 850px) {
      align-items: stretch;
    }
  }
`;

const QuoteWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding: 0 32px;
  margin-bottom: 8px;
  transform: translateY(20px);
  transition: all 600ms cubic-bezier(0.77, 0, 0.18, 1) 100ms;

  @media (max-width: 980px) {
    padding: 0;
  }

  @media (max-width: 850px) {
    margin-bottom: 24px;
    transform: none;
  }

  .slick-current & {
    transform: translateY(0);
    transition-delay: 0ms;
  }

  p {
    margin: 0;
    font-size: clamp(16px, 1.5vw, 23px);
  }
`;

const Quote: React.FC<any> = ({ quote }) => {
  return (
    <QuoteWrapper>
      <p>{quote.text.text}</p>
    </QuoteWrapper>
  );
};

const SliderDesktop: React.FC<any> = ({ quotes }) => {
  const [textSlider, setTextSlider] = useState<Slider | undefined>();
  const [imageSlider, setImageSlider] = useState<Slider | undefined>();
  const [creditSlider, setCreditSlider] = useState<Slider | undefined>();

  const slickSettingsDefault = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'ease-in-out',
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows: false,
  };

  const slickSettingsAutoplay = {
    ...slickSettingsDefault,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
  };

  const handleClick = (orientation: any) => {
    if (orientation === 'next') {
      textSlider?.slickNext();
    } else if (orientation === 'prev') {
      textSlider?.slickPrev();
    }
  };

  return (
    <div className="wrapper wrapper--desktop">
      <div className="quotes__text">
        <h2 className="carousel__title">Words from the Wild</h2>

        <div className="text-slider">
          <Slider
            {...slickSettingsAutoplay}
            asNavFor={imageSlider}
            ref={slider => setTextSlider(slider || undefined)}
          >
            {quotes.map((quote: any) => (
              <Quote quote={quote} key={quote.name} />
            ))}
          </Slider>
        </div>

        <div className="carousel__navigation">
          <button
            className="slideshow-arrows previous"
            type="button"
            aria-label="Previous"
            onClick={() => {
              handleClick('prev');
            }}
          />

          <Slider
            {...slickSettingsDefault}
            asNavFor={textSlider}
            ref={slider => setCreditSlider(slider || undefined)}
          >
            {quotes.map((quote: any) => (
              <div className="quote__credit" key={quote.name}>
                <p>{quote.name}</p>
                <p>{quote.company}</p>
              </div>
            ))}
          </Slider>

          <button
            className="slideshow-arrows next"
            type="button"
            aria-label="Next"
            onClick={() => {
              handleClick('next');
            }}
          />
        </div>
      </div>

      <div className="quotes__image">
        <Slider
          {...slickSettingsDefault}
          asNavFor={creditSlider}
          ref={slider => setImageSlider(slider || undefined)}
        >
          {quotes.map((quote: any) => (
            <img
              src={quote.image.fluid.src}
              alt={quote.image.alt}
              key={quote.name}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

const flickityOptions = {
  wrapAround: true,
  autoPlay: 10000,
  pageDots: false,
  prevNextButtons: false,
  cellAlign: 'center',
  selectedAttraction: 0.03,
  friction: 0.27,
	imagesLoaded: true,
};

const SliderMobile: React.FC<any> = ({ quotes }) => {
  const { ref, flickityRef } = useFlickity(flickityOptions);

  return (
    <div className="wrapper wrapper--mobile" ref={ref}>
      <h2 className="carousel__title">Words from the Wild</h2>

      <div className="mobile-slider" ref={flickityRef}>
        {quotes.map((quote: any, i: number) => (
          <div className="quote" key={i}>
            <div className="quote__inner">
              <div className="quotes__image">
                <img src={quote.image.fluid.src} alt={quote.image.alt} />
              </div>

              <div className="quotes__text">
                <Quote quote={quote} />
                <div className="quote__credit">
                  <p>{quote.name}</p>
                  <p>{quote.company}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const HITWQuotes = ({ data: { quotes } }: any) => {
  return (
    <Container>
      <SliderDesktop quotes={quotes} />
      <SliderMobile quotes={quotes} />
    </Container>
  );
};

export default HITWQuotes;
