import React from 'react';
import { Box, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import arrowDown from '../static/images/icons/arrow-down-white.svg';

const JoinContainer = styled(Box)`
  padding: 0 8%;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;

  @media (max-width: 700px) {
    padding: 0 30px;
    height: 260px;
  }

  .apply__subtitle {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 25px;
    line-height: 1;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .apply__title {
    width: 100%;
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
    font-size: 50px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-top: 0;
    padding: 0 24px;

    @media (max-width: 700px) {
      font-size: 33px;
    }

    span {
      white-space: nowrap;
    }
  }

  .apply__button {
    flex: 0 0 auto;
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    color: #ffffff;
    font-size: 30px;
    line-height: 1;
    display: flex;
    padding: 20px 24px 22px 32px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 50px;
    transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 1200px) {
      padding: 16px 20px 18px 24px;
      font-size: 26px;
    }

    @media (max-width: 1050px) {
      padding: 12px 20px 16px 24px;
      font-size: 22px;
      align-items: center;
    }

    &:hover {
      background-color: #1b4444;
      border-color: #1b4444;

      img {
        transform: rotate(-90deg) translateY(4px);
      }
    }

    img {
      width: 36px;
      height: 36px;
      transform: rotate(-90deg);
      margin-left: 16px;
      transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 1200px) {
        width: 28px;
        height: 28px;
      }
    }
  }
`;

const HITWFooter: React.FC<any> = ({ data }) => {
  const {
    apply_banner_subtitle,
    apply_banner_title,
    apply_banner_button_text,
    apply_banner_button_url,
    apply_banner_background_image,
  } = data;

  return (
    <JoinContainer
      className="hitw-footer"
      sx={{
        backgroundImage: apply_banner_background_image.url ? `url(${apply_banner_background_image.url})` : ``,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#1fafa4',
      }}
    >
      <Flex flexDirection="column" alignItems="center">
        {!!apply_banner_subtitle && (
          <div className="apply__subtitle">{apply_banner_subtitle}</div>
        )}
        <h2 className="apply__title">{apply_banner_title}</h2>
        <a href={apply_banner_button_url} className="apply__button">
          {apply_banner_button_text}
          <Image src={arrowDown} alt="Arrow" />
        </a>
      </Flex>
    </JoinContainer>
  );
};

export default HITWFooter;
